import React from "react";
import TypingAboutUs from "./TypingAboutUs";

const VideoSection: React.FC = () => {
  return (
    <div
      className="container max-w-5xl mx-auto px-4 my-12"
      id="video"
    >
      <TypingAboutUs />

      <div className="relative group overflow-hidden rounded-lg 
                      shadow-yellow-custom hover:shadow-yellow-hover 
                      transition-all duration-300
                       z-0"
      >
        {/*
          Use the padding-top trick for a 16:9 ratio:
          - container is h-0, pb-[56.25%]
          - iframe is absolute: fill that container
        */}
        <div className="relative w-full h-0 pb-[56.25%]">
          <iframe
            className="absolute inset-0 w-full h-full z-50"
            src="https://www.youtube.com/embed/8NgNXi0QQ8U?autoplay=1&mute=1"
            title="YouTube video"
            frameBorder="0"
            allow="accelerometer; autoplay; 
                   clipboard-write; encrypted-media; 
                   gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
};

export default VideoSection;













// import React, { useState, useRef } from "react";
// import TypingAboutUs from "./TypingAboutUs"; // Import the Typing Animation

// const VideoSection: React.FC = () => {
//   const [isPlaying, setIsPlaying] = useState(false);
//   const videoRef = useRef<HTMLVideoElement>(null);

//   const handlePlayPause = () => {
//     if (videoRef.current) {
//       if (isPlaying) {
//         videoRef.current.pause();
//         setIsPlaying(false);
//       } else {
//         videoRef.current.play();
//         setIsPlaying(true);
//       }
//     }
//   };

//   return (
//     <div
//      // className="relative container mx-auto !p-0 rounded-lg !my-12"
//       className="relative mx-4 md:mx-8 lg:mx-12 !p-0 rounded-lg !my-12"

//       id="video"
//     >
//       {/* Typing Animation Above Video */}
//       <TypingAboutUs />

//       {/* Video Section */}
//       <div className="relative group overflow-hidden rounded-lg shadow-yellow-custom hover:shadow-yellow-hover transition-all duration-300">
//         <video
//           className="w-full h-[400px] md:h-[500px] z-50"
//           ref={videoRef}
//           controlsList="nodownload" // Disables the download button
//           controls={isPlaying}
//           onClick={handlePlayPause}
//           autoPlay
//         >
//           <source src="/videos/video2.mp4" type="video/mp4" />
//           Your browser does not support the video tag.
//         </video>

//         {/* Play Button Overlay */}
//         {!isPlaying && (
//           <div
//             onClick={handlePlayPause}
//             className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 group-hover:bg-opacity-30 transition-all duration-300 cursor-pointer"
//           >
//             <div className="w-16 h-16 bg-white border-2 border-primary bg-opacity-90 rounded-full flex items-center justify-center">
//               <img src="/img/play_icon.svg" />
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default VideoSection;


//VideoSection.tsx


// import React, { useState, useRef } from "react";
// import TypingAboutUs from "./TypingAboutUs"; // Import the Typing Animation

// const VideoSection: React.FC = () => {
//   const [isPlaying, setIsPlaying] = useState(false);
//   const [currentVideo, setCurrentVideo] = useState("/videos/English.mp4"); // Default video source
//   const videoRef = useRef<HTMLVideoElement>(null);


//   // const videoSources: { [key: string]: string } = {
//   //   english: "/videos/English.mp4",
//   //   dutch: "/videos/Dutch.mp4",
//   //   german: "/videos/German.mp4",
//   //   french: "/videos/French.mp4",
//   // };

//   const videoSources: { [key: string]: string } = {
//     english: "https://dashmycvstore.blob.core.windows.net/public-files/videos/English.mp4",
//     dutch: "https://dashmycvstore.blob.core.windows.net/public-files/videos/Dutch.mp4",
//     german: "https://dashmycvstore.blob.core.windows.net/public-files/videos/German.mp4",
//     french: "https://dashmycvstore.blob.core.windows.net/public-files/videos/French.mp4",
//   };

//   const togglePlayPause = () => {
//     if (videoRef.current) {
//       if (isPlaying) {
//         videoRef.current.pause();
//         setIsPlaying(false);
//       } else {
//         videoRef.current.play();
//         setIsPlaying(true);
//       }
//     }
//   };

//   const handleSubtitleChange = (language: string) => {
//     setCurrentVideo(videoSources[language]);
  
//     if (videoRef.current) {
//       // Update video source first
//       videoRef.current.load(); // Reload the video with the new source
  
//       // Wait for the video metadata to load before playing
//       videoRef.current.onloadeddata = () => {
//         videoRef.current?.play(); // Play the video after it's loaded
//         setIsPlaying(true);
//       };
//     }
//   };

//   return (
//     <div
//       className="relative mx-4 md:mx-8 lg:mx-12 !p-0 rounded-lg !my-12"
//       id="video"
//     >
//       {/* Typing Animation Above Video */}
//       <TypingAboutUs />

//       {/* Video Section & Play Button */}
//       <div className="relative group overflow-hidden rounded-lg shadow-yellow-custom hover:shadow-yellow-hover transition-all duration-300">
//          {/* Video Section */}
//          <video
//           className="w-full h-[400px] md:h-[500px] z-50"
//           ref={videoRef}
//           controls
//           controlsList="nodownload" // Disables the download button
//           onPlay={() => setIsPlaying(true)} // Update state when video starts playing
//           onPause={() => setIsPlaying(false)} // Update state when video is paused
//           playsInline
//         >
//           <source src={currentVideo} type="video/mp4" />
//           Your browser does not support the video tag.
//         </video>


//         {/* Play Button Overlay */}
//         {!isPlaying && (
//           <div
//           onClick={togglePlayPause} // Toggle playback explicitly
//             className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 group-hover:bg-opacity-30 transition-all duration-300 cursor-pointer"
//           >
//             <div className="w-16 h-16 bg-white border-2 border-primary bg-opacity-90 rounded-full flex items-center justify-center">
//               <img src="/img/play_icon.svg" alt="Play" />
//             </div>
//           </div>
//         )}
//       </div>

//       {/* Subtitle Selection Dropdown */}
//       <div 
//         className="absolute bottom-0 right-0 bg-black bg-opacity-20 text-white rounded-lg p-1 md:p-1 z-50 transform translate-y-full"
//       >
//         <select
//           className="bg-transparant text-white text-xs p-1 border-none rounded w-14.5 h-6"
//           onChange={(e) => handleSubtitleChange(e.target.value)}
//           defaultValue="english"
//           style={{
//             position: "relative", // Allow custom dropdown menu styling
//           }}
//         >
//           <option value="english" style={{ backgroundColor: "rgba(246, 203, 14, 0.7)",  fontSize: "10px"}}>
//             English
//           </option>
//           <option value="french" style={{ backgroundColor: "rgba(246, 203, 14, 0.7)",  fontSize: "10px"}}>
//             French
//           </option>
//           <option value="dutch" style={{ backgroundColor: "rgba(246, 203, 14, 0.7)",  fontSize: "10px"}}>
//             Dutch
//           </option>
//           <option value="german" style={{ backgroundColor: "rgba(246, 203, 14, 0.7)",  fontSize: "10px"}}>
//             German
//           </option>
//         </select>
//       </div>
//     </div>
//   );
// };

// export default VideoSection;
